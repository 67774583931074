import React                   from 'react';
import Breakpoint              from 'react-socks';
import { Container, Row, Col } from 'react-grid-system';

import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Boxgroup          from '@interness/theme-twentynineteen/src/components/Boxgroup/Boxgroup';
import Display           from '@interness/theme-twentynineteen/src/components/Display/Display';
import CTASection        from '@interness/web-core/src/components/modules/CTASection/CTASection';
import RandomBrandHeaderImages
                         from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import MoreExpander      from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import Story             from '../components/Story/Story';
import ResponsiveIFrame  from '@interness/web-core/src/components/structure/iFrame/iFrame';
import Mounted           from '@interness/web-core/src/components/structure/Mounted/Mounted';

const IndexPage = () => {
  return (
    <>
      <RandomBrandHeaderImages count={3}/>
      <Mounted>
        <Breakpoint large up>
          <Boxgroup/>
          <Spacer/>
        </Breakpoint>
      </Mounted>
      <Wrapper>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Wamser - Zeit und Schmuck</Heading>
          <Spacer height={20}/>
          <span style={{ textAlign: 'justify' }}>
          <p>Hallo und willkommen bei Uhren Wamser in Erbach, im Herzen des Odenwaldes.</p>
          <p>Auf traditionelle Weise trägt Uhren Wamser seit vielen Jahren in Erbach dazu bei, Ihre Wünsche und Träume bei Uhren, Schmuck und exklusiven Trauringen und Eheringen zu erfüllen.
          Wir freuen uns auf Ihren Besuch in unserem modernen Geschäft in der Werner-von-Siemens-Str. 17, wo wir uns in entspannter Atmosphäre ausgiebig Zeit für Ihre Wünsche nehmen möchten.</p>
          <p>Wir legen größten Wert auf individuelle und persönliche Beratung. Eine Tugend und Selbstverständlichkeit unseres Hauses.
          Wir laden Sie herzlich ein, bei Ihrem Besuch in unserem Geschäft Ihre Lieblingsstücke auszuwählen, anzuprobieren und zu begutachten.
          In unserem fein ausgewählten Sortiment finden Sie sowohl erlesenen als auch top aktuellen Schmuck, moderne und klassische Uhren, traumhafte Trauringe und Eheringe sowie extravagante Accessoires.</p>
          <p>Ihre Lieblinge sind bei unserem Uhrmachermeister in unserer Meisterwerkstatt gut aufgeboben. Hier bekommen Sie unseren perfekten Service und erstklassige Reparaturen.<br/>
          Wir kaufen Ihr Altgold zu fairen und tagesaktuellen Preisen an.</p>
          <p>BIS BALD IN ERBACH<br/>IHRE FAMILIE WAMSER</p>
          </span>
        </section>
        <section>
          <Heading tag={'h2'}>Unsere Markenwelt</Heading>
          <Spacer height={20}/>
          <Display exclude={['wedding_rings']}/>
        </section>
        <Spacer height={20}/>
        <section>
          <Heading tag={'h2'}>Unser Service für Sie</Heading>
          <p>
            Professioneller Service gehört seit jeher zu den wesentlichen Tugenden unseres Hauses. Wir reparieren Ihre
            Armbanduhren, Ihre Großuhren, führen Goldschmiedereparaturen aus und kaufen Ihr Altgold an.
          </p>
          <MoreExpander>
            <Container fluid>
              <Row>
                <Col md={6}>
                  <h2>Uhren</h2>
                  <ul>
                    <li>mechanische Uhren / Handaufzug</li>
                    <li>Quarzarmbanduhren</li>
                    <li>Automatikuhren</li>
                    <li>Batteriewechsel</li>
                    <li>Komplette Revisionen</li>
                    <li>Taschenuhren</li>
                    <li>Glasersatz</li>
                    <li>Leder-, Metall-, Kunststoffuhrarmbänder</li>
                    <li>Bandanpassung</li>
                  </ul>
                </Col>
                <Col md={6}>
                  <h2>Goldschmiede</h2>
                  <ul>
                    <li>Goldschmiedereparaturen aller Art</li>
                    <li>Kette und Ösen zulöten, Verschlüsse ersetzen</li>
                    <li>Ringgrößen ändern</li>
                    <li>Perlenketten neu aufziehen oder knoten</li>
                    <li>Trauringumarbeitung</li>
                    <li>Schmuck reinigen und aufarbeiten</li>
                    <li>Gravuren in Ringe, Anhänger, Uhren, usw.</li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h2>Großuhren</h2>
                  <ul>
                    <li>Heim – und Tischuhren, Jahresuhren</li>
                    <li>Standuhren</li>
                    <li>Regulatoren</li>
                  </ul>
                </Col>
                <Col md={6}>
                  <h2>Altgoldankauf</h2>
                  <ul>
                    <li>Wir kaufen Altgold aus vielen, unterschiedlichen Bereichen an:</li>
                    <li>Zahngold, Goldnuggets, Altgold aus Schmuckstücken und Münzen</li>
                    <li>Wir prüfen fachkundig und unkompliziert und vergüten nach aktuellen Edelmetallkursen</li>
                    <li>Altgoldankauf ist Vertrauenssache</li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </MoreExpander>
          <Spacer height={20}/>
        </section>
        <Spacer height={20}/>
      </Wrapper>
      <CTASection/>
    </>
  )
};

export default IndexPage;